import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import MainContent from "./MainContent";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Verifica si existe un valor en localStorage y si no ha expirado
    const authData = JSON.parse(localStorage.getItem("authData"));
    if (authData && authData.expiry > Date.now()) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem("authData"); // Limpia datos expirados
    }
  }, []);

  const handleLogin = (isLoggedIn) => {
    setIsAuthenticated(isLoggedIn);

    if (isLoggedIn) {
      // Calcula el tiempo de expiración (2 horas desde ahora)
      const expiry = Date.now() + 2 * 60 * 60 * 1000; // 2 horas en milisegundos
      localStorage.setItem("authData", JSON.stringify({ isAuthenticated: true, expiry }));
    } else {
      localStorage.removeItem("authData");
    }
  };

  return (
    <Router>
      <Routes>
        {/* Ruta para el inicio de sesión */}
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/" replace /> : <Login onLogin={handleLogin} />
          }
        />

        {/* Ruta protegida que solo muestra MainContent si está autenticado */}
        <Route
          path="/"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <MainContent />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
